import Fuse from "fuse.js";

export default {
	computed: {
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			let result = this.tableData;
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		}
	},
	data() {
		return {
			pagination: {
				perPage: 1000,
				currentPage: 1,
				perPageOptions: [10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
				total: 0
			},
			searchQuery: "",
			searchedData: [],
			fuseSearch: null
		};
	},
	methods: {
		sortChange({prop, order}) {
			if (prop) {
				this.tableData.sort((a, b) => {
					let aVal = a[prop];
					let bVal = b[prop];
					if (order === "asc") {
						return aVal > bVal ? 1 : -1;
					}
					return bVal - aVal ? 1 : -1;
				});
			} else {
				this.tableData.sort((a, b) => {
					return a.id - b.id;
				});
			}
		}
	},
	mounted() {
		// Fuse search initialization.
		this.fuseSearch = new Fuse(this.tableData, {
			keys: ["name", "email"],
			threshold: 0.3
		});
	},
	watch: {
		/**
		 * Searches through the table data by a given query.
		 * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
		 * @param value of the query
		 */
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		}
	}
};
